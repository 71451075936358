<template>
    <div>
        <el-form
			ref="verificateForm"
			:rules="verificateRule"
			:model="verificateData"
			label-position="left"
			label-width="100px">
			<el-form-item
				label="验证方式"
				prop="loginName"
				style="width:100%">
				<el-select
					v-model="verificateData.verificateMode"
					style="width:100%">
					<el-option
						v-for="item in verificateModeList"
						:key="item.value"
						:label="item.label"
						:value="item.value"/>
				</el-select>
			</el-form-item>
			<el-form-item
				v-if="'cellphone' === verificateData.verificateMode"
				label="手机号码"
				prop="cellphoneNum"
				style="width:100%">
				<el-input
					v-model="verificateData.cellphoneNum"
					clearable
					placeholder="请输入手机号码"
					style="width:50%" />
				<el-button
					type="primary"
					style="width:45%; margin-left: 5%"
					:loading="phoneVeriCodeNextTime > 0"
					@click="onLoginGetPhoneVeriCodeBtnClk">
					{{ phoneVeriCodeNextTime > 0 ? '(' + phoneVeriCodeNextTime + ')' : '获取验证码' }}
				</el-button>
			</el-form-item>
			<el-form-item
				v-else-if="'email' === verificateData.verificateMode"
				label="邮箱地址"
				prop="email"
				style="width:100%">
				<el-input
					v-model="verificateData.email"
					clearable
					placeholder="请输入邮箱地址"
					style="width:50%" />
				<el-button
					type="primary"
					style="width:45%; margin-left: 5%"
					:loading="emailVeriCodeNextTime > 0"
					@click="onLoginGetEmailVeriCodeBtnClk">
					{{ emailVeriCodeNextTime > 0 ? '(' + emailVeriCodeNextTime + ')' : '获取验证码' }}
				</el-button>
			</el-form-item>
			<el-form-item
				label="验证码"
				prop="verificateCode">
				<el-input
					v-model="verificateData.verificateCode"
					clearable
					placeholder="请输入验证码"
					style="width:100%" />
			</el-form-item>
		</el-form>
		<el-button
			class="loginButton"
			@click.native.prevent="loginByVeriCode"
			type="primary">
			登录
		</el-button>
    </div>
</template>

<script>
import {
    getEmailLoginVeriCode,
    loginByVeriCode
} from "@/api/webLogin.js"
export default {
    name: 'VeriCodeLogin',

    data() {
        return {
            timerHandle: null,

            verificateModeList: [{
                value: 'cellphone',
                label: '手机验证',
            }, {
                value: 'email',
                label: '邮箱验证',
            }],

            verificateRule: {
                verificateMode: [{
                    required: true,
                    message: "验证模式不能为空",
                    trigger: "blur"
                }],

                cellphoneNum: [{
                    required: true,
                    message: "手机号码不能为空",
                    trigger: "blur"
                }],

                email: [{
                    required: true,
                    message: "邮箱地址不能为空",
                    trigger: "blur"
                }],

                verificateCode: [{
                    required: true,
                    message: "验证码不能为空",
                    trigger: "blur"
                }]
            },

            // 验证码登录框的数据
            verificateData: {
                verificateMode: 'cellphone',
                cellphoneNum: '',
                email: '',
                verificateCode: ''
            },

            phoneVeriCodeNextTime: 0, // 手机验证码剩余时间
            emailVeriCodeNextTime: 0, // 邮箱验证码剩余时间
        }
    },

    mounted() {
        this.timerHandle = setInterval(() => {
            if (this.phoneVeriCodeNextTime > 0) {
                this.phoneVeriCodeNextTime -= 1; // 如果手机验证码的时间不是0，那么手机验证码的剩余时间-1s
            }
            if (this.emailVeriCodeNextTime > 0) {
                this.emailVeriCodeNextTime -= 1; // 如果邮箱验证码的时间不是0，那么邮箱验证码的剩余时间-1s
            }
        }, 1000)
    },

    beforeRouteLeave() {
        clearInterval(this.timerHandle);
    },

    methods: {
        loginByVeriCode() {
            // veriMode, veriCode, loginName
            this.$refs["verificateForm"].validate(valid => {
                if (false === valid) {
                    return;
                }

                // 检查是不是手机登录
                if ('cellphone' == this.verificateData.verificateMode) {
                    this.$message({
                        message: "暂时不支持手机验证功能",
                        type: "error",
                        duration: 3 * 1000
                    });
                    return;
                }
                
                // loginByVeriCode(this.verificateData.verificateMode, this.verificateData.verificateCode, this.verificateData.email).then((res) => {
                //     this.$router.push('main');
                //     return;
                // })
                
                this.$store
                .dispatch("user/loginByVeriCode", {veriMode:this.verificateData.verificateMode, veriCode:this.verificateData.verificateCode, loginName:this.verificateData.email})
                .then(() => {
                    this.$router.push({
                        path: "/main"
                    });
                })
                .catch(error => {
                    console.log(error);
                });
            });            
        },

        onLoginGetPhoneVeriCodeBtnClk() {
            // 获取手机验证码
            // this.phoneVeriCodeNextTime = 120;
            this.$message({
                message: "暂时不支持手机验证功能",
                type: "error",
                duration: 3 * 1000
            })
        },

        onLoginGetEmailVeriCodeBtnClk() {
            // 获取邮箱验证码
            this.$refs['verificateForm'].validateField('email', valid => {
                if ('' !== valid) {
                    return;
                }

                getEmailLoginVeriCode(this.verificateData.email).then((res) => {
                    this.$message({
                        message: res.data,
                        type: "success",
                        duration: 3 * 1000
                    })
                    this.emailVeriCodeNextTime = 120;
                }, (error) => {
                    this.$message({
                        message: error.msg,
                        type: "error",
                        duration: 3 * 1000
                    })
                })
            })
        },
    }
}
</script>

<style lang="less" scoped>
.loginButton {
    float: middle;
    width: 80%;
    margin-left: 10%;
    height: 50px;
    border-radius: 25px;
    font-size: 15pt;
}
</style>
// 集控首页统计数据的接口

import request from '@/utils/request'

/**
 * 获取项目实时状态
 */
export function getActualTrouble(param){
    return request({
        url: '/api/center/getActualTrouble',
        method: 'get',
        param
    })
}

/**
 * 查询统计数据
 */
 export function getStatisticsData(param){
    return request({
        url: '/api/center/getStatisticsData',
        method: 'get',
        param
    })
}
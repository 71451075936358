import request from '@/utils/request'

export function getResetPwdVeriCodeByEmail(email) {
    return request({
        url: '/WebResetPwd/GetEmailVeriCode',
        method: 'post',
        data: {
            'email': email
        }
    })
}

export function getResetPwdVeriCodeByPhone(email) {
    return request({
        url: '/WebResetPwd/GetPhoneVeriCode',
        method: 'post',
        data: {
            'loginName': loginName,
            'password': pwd
        }
    })
}

export function resetPasswordByVeriCode(data) {
    return request({
        url: '/WebResetPwd/ResetPasswordByVeriCode',
        method: 'post',
        data
    })
}
import request from '@/utils/request'
// 获取用户信息（角色，组织（暂定））
export function getInfo(data) {
  return request({
    url: '/api/role/getUserRole',
    method: 'get',
    params:data
  })
}

// 退出
export function logout() {
  return request({
    url: '/vue-element-admin/user/logout',
    method: 'post'
  })
}

<template>
        <!-- style的宽高一定要写，不写也不会展示echarts图 -->
        <div id="container" ref="myEcharts"></div>
</template>
<script>
import chinaJson from "@/assets/json/china.json";
import {getAllAccessablePjt4Map} from '@/api/orgAndProject.js';
export default {
data() {
  return {
    projectList:[],
    orgId:''
  };
},
props:{
  "orgvalue":{
    type:String
  },
},
mounted() {
  this.orgId = this.orgvalue
  this.getProjectList();
},
methods: {
  getProjectList(){
    getAllAccessablePjt4Map().then(res=>{
      const {projects,bessPower,bessEnergy,totalDischarge,totalCharge,rtPower,onlineProjectCnt} = res.data;
      var data6 = {
        bessPower,
        bessEnergy,
        totalDischarge,
        totalCharge,
        rtPower,
        onlineProjectCnt
      }
      this.$emit("getData6",data6);
      this.projectList = projects;
      this.chinaEcharts();
    });
  },
  chinaEcharts() {
    var _that = this
    //1.注册一个地图
    this.$echarts.registerMap("china", chinaJson);

    //2.初始化echarts
    let myChart = this.$echarts.init(this.$refs.myEcharts);
  
    // 3.准备数据
    //'#2A6DEC'
    var data = [
          { name: '北京', selected: false, value: 1 ,itemStyle:{normal:{areaColor:'#2A6DEC',label:{show:false}}}},
          { name: '天津', selected: false, value: 2 ,itemStyle:{normal:{areaColor:'#77C4AB',label:{show:false}}}},
          { name: '上海', selected: false, value: 3 ,itemStyle:{normal:{areaColor:'#003194',label:{show:false}}}},
          { name: '重庆', selected: false, value: 4 ,itemStyle:{normal:{areaColor:'#5A8BE5',label:{show:false}}}},
          { name: '河北', selected: false, value: 5 ,itemStyle:{normal:{areaColor:'#1658B6',label:{show:false}}}},
          { name: '河南', selected: false, value: 6 ,itemStyle:{normal:{areaColor:'#004094',label:{show:false}}}},
          { name: '云南', selected: false, value: 7 ,itemStyle:{normal:{areaColor:'#0747C3',label:{show:false}}}},
          { name: '辽宁', selected: false, value: 8 ,itemStyle:{normal:{areaColor:'#2D95ED',label:{show:false}}}},
          { name: '黑龙江', selected: false, value: 9 ,itemStyle:{normal:{areaColor:'#1658B6',label:{show:false}}}},
          { name: '湖南', selected: false, value: 10 ,itemStyle:{normal:{areaColor:'#83ABF3',label:{show:false}}}},
          { name: '安徽', selected: false, value: 11 ,itemStyle:{normal:{areaColor:'#286FEB',label:{show:false}}}},
          { name: '山东', selected: false, value: 12 ,itemStyle:{normal:{areaColor:'#296EEB',label:{show:false}}}},
          { name: '新疆', selected: false, value: 13 ,itemStyle:{normal:{areaColor:'#296EEB',label:{show:false}}}},
          { name: '江苏', selected: false, value: 14 ,itemStyle:{normal:{areaColor:'#1658B6',label:{show:false}}}},
          { name: '浙江', selected: false, value: 15 ,itemStyle:{normal:{areaColor:'#1658B6',label:{show:false}}}},
          { name: '江西', selected: false, value: 16 ,itemStyle:{normal:{areaColor:'#2C62B4',label:{show:false}}}},
          { name: '湖北', selected: false, value: 17 ,itemStyle:{normal:{areaColor:'#175AB5',label:{show:false}}}},
          { name: '广西', selected: false, value: 18 ,itemStyle:{normal:{areaColor:'#00ABF9',label:{show:false}}}},
          { name: '甘肃', selected: false, value: 19 ,itemStyle:{normal:{areaColor:'#1658B6',label:{show:false}}}},
          { name: '山西', selected: false, value: 20 ,itemStyle:{normal:{areaColor:'#009AFC',label:{show:false}}}},
          { name: '内蒙古', selected: false, value: 21 ,itemStyle:{normal:{areaColor:'#004094',label:{show:false}}}},
          { name: '陕西', selected: false, value: 22 ,itemStyle:{normal:{areaColor:'#3DE4FD',label:{show:false}}}},
          { name: '吉林', selected: false, value: 23 ,itemStyle:{normal:{areaColor:'#0BB2E7',label:{show:false}}}},
          { name: '福建', selected: false, value: 24 ,itemStyle:{normal:{areaColor:'#296EEB',label:{show:false}}}},
          { name: '贵州', selected: false, value: 25 ,itemStyle:{normal:{areaColor:'#009AFC',label:{show:false}}}},
          { name: '广东', selected: false, value: 26 ,itemStyle:{normal:{areaColor:'#1658B6',label:{show:false}}}},
          { name: '青海', selected: false, value: 27 ,itemStyle:{normal:{areaColor:'#004094',label:{show:false}}}},
          { name: '西藏', selected: false, value: 28 ,itemStyle:{normal:{areaColor:'#1658B6',label:{show:false}}}},
          { name: '四川', selected: false, value: 29 ,itemStyle:{normal:{areaColor:'#296EEB',label:{show:false}}}},
          { name: '宁夏', selected: false, value: 30 ,itemStyle:{normal:{areaColor:'#3DE4FD',label:{show:false}}}},
          { name: '海南', selected: false, value: 31 ,itemStyle:{normal:{areaColor:'#296EED',label:{show:false}}}},
          { name: '台湾', selected: false, value: 32 ,itemStyle:{normal:{areaColor:'#0DB2E5',label:{show:false}}}},
          { name: '香港', selected: false, value: 33 ,itemStyle:{normal:{areaColor:'#dc9bbb',label:{show:false}}}},
          { name: '澳门', selected: false, value: 34 ,itemStyle:{normal:{areaColor:'#e0f7cc',label:{show:false}}}},
          { name: '南海诸岛', selected: false, value: 34 ,itemStyle:{normal:{opacity: 0}},label:{show:false}}
    ];
    // var mapData = [
    //       { name: "北京", value: [116.41995, 40.18994],url:'/manage/system',state:1},
    //       { name: "郑州", value: [113.665412, 34.757975],url:'/manage/system',state:2},
    //       { name: "天津", value: [117.205126, 39.034933],url:'/manage/system',state:1},
    //       { name: "广州", value: [113.26453, 23.155008],url:'/manage/system',state:4}
    // ]
    // var mapData_fault = [
    //       { name: "昆明", value: [102.81844, 24.906231],url:'/manage/system',state:3},
    // ]
    var mapData = [];
    var mapData_fault = [];
    console.log(this.projectList)
    this.projectList.forEach((value)=>{
      const {displayName,longitude,latitude,statusCode,browserAddr,power} = value
      if(statusCode===3){
        mapData_fault.push({name:displayName,value:[longitude,latitude],url:browserAddr,state:statusCode,power:power})
      }else{
        mapData.push({name:displayName,value:[longitude,latitude],url:browserAddr,state:statusCode,power:power})
      }
    })
    //5.echarts配置
    let option = {
      tooltip: {
            show: true,
            position: "top",
            trigger : 'item',
            className: 'custom-tooltip-box',
            formatter: function(params) {
              
              console.log(params)
              if (params.componentSubType == "map") {
                
              } else {
                var color = ''
                var state = ''
                if(params.data.state === 1){
                  color = '#4CB461'
                  state = '运行'
                }else if(params.data.state === 2){
                  color = '#fae16a'
                  state = '待机'
                }else if(params.data.state === 3){
                  color = 'red'
                  state = '故障'
                }else if(params.data.state === 4){
                  color = '#848588'
                  state = '离线'
                }else if(params.data.state === 99){
                  color = '#848588'
                  state = '过期'
                }
                var htmlStr = '';
                if(params.data.state!=99){
                  htmlStr = `<div class="custom-tooltip-style">
                      <p>项目名称：<span style="color:#4CB461">${params.name}</span></p>
                      <p>实时功率：<span style="color:#4CB461">${params.data.power}kW</span></p>
                      <p>当前状态：<span style="color:${color}">${state}</span></p>
                    </div>`
                }else{
                  htmlStr = `<div class="custom-tooltip-style">
                      <p>项目名称：<span style="color:#848588">${params.name}</span></p>
                      <p>当前状态：<span style="color:${color}">${state}</span></p>
                    </div>`
                }
                 
                return htmlStr;
              }
            },
      },
      
      geo:{
        map:'china',
        mapType: 'china',
          index:0,
          zoom:1.2,
          silent: true,
          itemStyle:{
            normal:{
              borderWidth: 0,
            }
          },
          label: {
            normal: {
              show: false,
              color:'#fff'
            },
            emphasis: {
              show: false,
              textStyle: {
                color: "#fff"
              }
            }
          },
          roam: true,
          animation: false,
          regions:data,
      },
      series: [
        {
          name: "effectScatter",
          type: "effectScatter",
          // 基础配置 也就是关系图一的配置
          coordinateSystem: "geo",
          // 这五个点的数据
          data: mapData,
          // 点的大小
          symbolSize: 13,
          showEffectOn: "render", // render绘制完成时显示特效
          // 波纹效果
          rippleEffect: {
            brushType: "stroke", //波纹绘制方式
            // color: '#00E3FF', //波纹的颜色
            period: 5, //动画的时间
            scale: 2, //波纹环的大小限制
          },
          hoverAnimation: true, //鼠标移入会放大
          // 字体的颜色
          itemStyle: {
            color: function(param) {
              if (param.data.state == 1) {
                return "#4CB461"
              } else if (param.data.state == 2) {
                return "#fae16a"
              }else if (param.data.state == 3) {
                return 'red'
              } else {
                return "#848588"
              }
            },
          }
        },
        {
          name: "effectScatter",
          type: "effectScatter",
          // 基础配置 也就是关系图一的配置
          coordinateSystem: "geo",
          // 这五个点的数据
          data: mapData_fault,
          // 点的大小
          symbolSize: 15,
          showEffectOn: "render", // render绘制完成时显示特效
          // 波纹效果
          rippleEffect: {
            brushType: "fill", //波纹绘制方式
            // color: '#00E3FF', //波纹的颜色
            period: 1, //动画的时间
            scale: 4, //波纹环的大小限制
          },
          hoverAnimation: true, //鼠标移入会放大
          // 字体的颜色
          itemStyle: {
            color: function(param) {
              if (param.data.state == 1) {
                return "#4CB461"
              } else if (param.data.state == 2) {
                return "#fae16a"
              }else if (param.data.state == 3) {
                return 'red'
              } else {
                return "#848588"
              }
            },
          }
        }
      ]
    };
    // 点击触发
    myChart.on("click", (param) => {
        if (param.componentSubType == "effectScatter") {
          mapData.forEach((item) => {
            if (param.name == item.name) {
              // this.$router.push(item.url)
              // url一定要带域名/ip加端口
              window.open(item.url)//重新打开一个浏览器tab
            }
          })
        }
    })
    myChart.setOption(option);
    window.addEventListener("resize", function() {
      myChart.resize();
    });
  }
}
};
</script>
<style lang="less" scoped>
#container{
  width:100%;
  height:100%;
}
/deep/ .custom-tooltip-box{
  padding:0 !important;
  border: none !important;
  // background-color: rgba(139, 131, 131, 0.4) !important;
  background-color: transparent !important;
  text-align: left;
  .custom-tooltip-style{
    width:auto;
    padding:10px;
    // background-image:url('@/assets/main/tootipBorder.png');
    background-image:url('@/assets/main/bg6.png');
    background-repeat: no-repeat;
    background-size:100% 100%;  
    text-align:left;
    color:#fff
}
}

</style>



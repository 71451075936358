// 这里是引用接口
import { logout, getInfo } from "@/api/user";
import { loginByPwd,loginByVeriCode } from '@/api/webLogin'
// 引用鉴权方法 获取token ，设置token 和 移除token的三个方法以及获取用户名的方法
import {
    getToken,
    setToken,
    removeToken,

    getUsername,
    setUsername,
    removeUsername,

    getUserId,
    setUserId,
    removeUserId,

    getOrgId,
    setOrgId,
    removeOrgId,

    getOrgName,
    setOrgName,
    removeOrgName,

    getRoleId,
    setRoleId,
    removeRoleId,

    getProjectList,
    setProjectList,
    removeProjectList,

    getResourceList,
    setResourceList,
    removeResourceList,

    getOrganizationList,
    setOrganizationList,
    removeOrganizationList,

} from "@/utils/auth";

const state = {
    token(){
        return getToken();
    },
    username(){
        return getUsername();
    },
    userId(){
      return getUserId();
    },
    orgId:'',// 组织id
    orgName:'',// 组织名
    organizationList:[],// 组织列表
    projectList:[],// 项目列表
    roleId:'',// 角色Id
    roleName:'',// 角色名
    resourceList:'',//路由及按钮资源列表
    roles:[]// 用户所属的角色
}

// 这里是 mutation  可以理解为实例对应的方法
const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
        sessionStorage.setItem("token", token);
    },
    SET_USERNAME: (state, username) => {
        state.username = username;
        sessionStorage.setItem(`username`, username);
    },
    SET_USERID: (state, userId) =>{
        state.userId = userId;
        sessionStorage.setItem(`userId`, userId);
    },


    SET_ORGID: (state, orgId) =>{ //组织ID
      state.orgId = orgId;
      sessionStorage.setItem(`orgId`, orgId);
    },
    SET_ORGNAME: (state, orgName) =>{//组织名
      state.orgName = orgName;
      sessionStorage.setItem(`orgName`, orgName);
    },
    SET_ORGANIZATIONLIST:(state,organizationList) =>{// 组织列表
      state.organizationList = organizationList;
      sessionStorage.setItem(`organizationList`, JSON.stringify(organizationList));
    },

    SET_PROJECTLIST: (state, projectList) =>{// 项目列表
      state.projectList = projectList;
      sessionStorage.setItem(`projectList`, JSON.stringify(projectList));
    },


    SET_ROLEID: (state, roleId) =>{ //角色ID
      state.roleId = roleId;
      sessionStorage.setItem(`roleId`, roleId);
    },
    SET_ROLENAME: (state, roleName) =>{//角色名
      state.roleName = roleName;
      sessionStorage.setItem(`roleName`, roleName);
    },


    SET_RESOURCELIST: (state, resourceList) =>{// 路由以及按钮资源列表
      state.resourceList = resourceList;
      sessionStorage.setItem(`resourceList`, JSON.stringify(resourceList));
    },


    SET_ROLES: (state, roles) => {// 角色数组（应该没有用了）
        state.roles = roles;
        sessionStorage.setItem(`roles`, roles);
    },
}
// 如果是异步改变状态 就是操作action
const actions = {
    // user login
    // 这里的commit是简略写法，就是为了省去context这个直接进行commit调用
    // 第一个login是vueX中定义的方法，第二个login是api中定义的方法
    loginByPwd({ commit }, userInfo) {
      const { username, password } = userInfo;
      // 这里返回的是一个promise对象
      return new Promise((resolve, reject) => {
        loginByPwd(username.trim(), password)
          .then(response => {        
            //   如果登录成功，就把接口中获取到的Token存储
            //   这里一定是要有token的 如果不设置token 是会报错的 无法登录成功的
            let token = response.data.Authorization;// token
            let username = response.data.userName;// 用户名
            let userId = response.data.userId;// 用户ID
            //  commit是在调用mutations里面的方法
            commit("SET_TOKEN", token); // 这里是同步操作  将token存入sessionstorage中和vuex的state全局变量中
            commit("SET_USERNAME", username);// 将username存入sessionstorage中和vuex的state全局变量中
            commit("SET_USERID", userId);// 将username存入sessionstorage中和vuex的state全局变量中
            
            setToken(token); //   在更新store的同时 将token存入cookie中
            setUsername(username); //   在更新store的同时 将username存入cookie中
            setUserId(userId); //   在更新store的同时 将userId存入cookie中

            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    loginByVeriCode({ commit }, userInfo) {
      const { veriMode, veriCode,loginName } = userInfo;
      // 这里返回的是一个promise对象
      return new Promise((resolve, reject) => {
        loginByVeriCode(veriMode, veriCode,loginName)
          .then(response => {
            //   如果登录成功，就把接口中获取到的Token存储
            //   这里一定是要有token的 如果不设置token 是会报错的 无法登录成功的
            let token = response.data.Authorization;// token
            let username = response.data.userName;// 用户名
            let userId = response.data.userId;// 用户ID

            //  commit是在调用mutations里面的方法
            commit("SET_TOKEN", token); //   这里是同步操作  将token存入sessionstorage中和vuex的state全局变量中
            commit("SET_USERNAME", username);// 将username存入sessionstorage中和vuex的state全局变量中
            commit("SET_USERID", userId);// 将username存入sessionstorage中和vuex的state全局变量中

            setToken(token); //   在更新store的同时 将token存入cookie中
            setUsername(username); //   在更新store的同时 将username存入cookie中
            setUserId(userId); //   在更新store的同时 将userId存入cookie中

            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // get user info
    getInfo({ commit, state }) {
      // console.log(state.token())
      // console.log(state.userId())
      return new Promise((resolve, reject) => {
        var userId = getUserId();
        // 调取获取用户信息接口，主要就是获取用户的角色，组织(暂定)等
        getInfo({userId}).then(response => {
          const { data } = response
          if(!data){
            reject('无法获取用户相关信息，请重新登录')
          }
          const { orgId,orgName,organizationList,projectList,roleId,roleName,resourceList } = data
          
          var roles = [];
          roles.push(roleId)
          // 角色必须是一个非空的数组
          // if (!roles || roles.length <= 0) {
          //   reject('获取用户信息: 角色必须是一个非空数组!')
          // }
          commit("SET_ROLES", roles);

          commit("SET_ORGID", orgId);
          commit("SET_ORGNAME", orgName);
          commit("SET_ORGANIZATIONLIST", organizationList);
          commit("SET_PROJECTLIST", projectList);
          commit("SET_ROLEID", roleId);
          commit("SET_ROLENAME", roleName);
          commit("SET_RESOURCELIST", resourceList);
          

          setOrgId(orgId);
          setOrgName(orgName);
          setOrganizationList(organizationList);
          setProjectList(projectList);

          setRoleId(roleId);
          setResourceList(resourceList);

          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
  
    // user logout 没有接口就先只清除token
    logout({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        //  将store中的token清空
        //  将角色也清空
        commit("SET_TOKEN", "");
        commit("SET_ROLES", []);
        commit("SET_USERNAME", "");
        commit("SET_USERID", "");
        //  移除浏览器中的token
        removeToken();
        //  这里是路由重置 将路由重置为没有角色权限的路由 就是login
        // resetRouter(); 
        resolve();
      });
    },
  
    // 清空重置Token的方法
    resetToken({ commit }) {
      return new Promise(resolve => {
        commit("SET_TOKEN", "");
        commit("SET_ROLES", []);

        commit("SET_USERNAME","");
        commit("SET_USERID","");

        commit("SET_ORGID","");
        commit("SET_ORGNAME","");
        commit("SET_ORGANIZATIONLIST","");

        commit("SET_PROJECTLIST",[]);

        commit("SET_ROLEID","");
        commit("SET_ROLENAME","");

        commit("SET_RESOURCELIST",[]);

        removeToken();
        removeUsername();
        removeUserId();
        removeOrgId();
        removeOrgName();
        removeOrganizationList(),
        removeRoleId();
        removeProjectList();
        removeResourceList();

        resolve();
      });
    },
  
    // dynamically modify permissions
    // async changeRoles({ commit, dispatch }, role) {
    //   const token = role + "-token";
  
    //   commit("SET_TOKEN", token);
    //   setToken(token);
  
    //   const { roles } = await dispatch("getInfo");
  
    //   resetRouter();
  
    //   // generate accessible routes map based on roles
    //   const accessRoutes = await dispatch("permission/generateRoutes", roles, {
    //     root: true
    //   });
    //   // dynamically add accessible routes
    //   router.addRoutes(accessRoutes);
  
    //   // reset visited views and cached views
    //   dispatch("tagsView/delAllViews", null, { root: true });
    // }
};
export default {
    namespaced:true,
    state,
    mutations,
    actions
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/login.vue'
import Main from '../views/main.vue'
import Index from '../views/index4.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
  },
  {
    path: '/manage',
    name: 'manage',
    component: () => import("@/views/manage/index.vue"),
    children:[
      {
        path: "system",
        component: () => import("@/views/manage/system.vue")
      },
      {
        path: "actualdata",
        component: () => import("@/views/manage/data/actualdata.vue")
      },
      {
        path: "historydata",
        component: () => import("@/views/manage/data/historydata.vue")
      },
      {
        path: "faultdata",
        component: () => import("@/views/manage/data/faultdata.vue")
      },
      {
        path: "operation",
        component: () => import("@/views/manage/operation.vue")
      },
      {
        path: "report",
        component: () => import("@/views/manage/report.vue")
      },
      {
        path: "system_anaylyse",
        component: () => import("@/views/manage/analyse/system.vue")
      },
      {
        path: "exception_anaylyse",
        component: () => import("@/views/manage/analyse/exception.vue")
      },
      {
        path: "battery_anaylyse",
        component: () => import("@/views/manage/analyse/battery.vue")
      },
      {
        path: "equipment",
        component: () => import("@/views/manage/equipment.vue")
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router

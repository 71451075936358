<template>
    <div>
        <el-form
			ref="regForm"
			:rules="regRule"
			:model="regData"
			label-position="left"
			label-width="100px">
			<p class="formTitle">注册</p>
			<el-form-item
				label="用户名"
				prop="userName"
				style="width:100%">
				<el-input
					v-model="regData.userName"
					clearable
					placeholder="请输入用户名"
					style="width:100%" />
			</el-form-item>
			<el-form-item
				label="手机号码"
				prop="phone"
				style="width:100%">
				<el-input
					v-model="regData.phone"
					clearable
					placeholder="请输入手机号码"
					style="width:50%" />
				<el-button
					type="primary"
					style="width:45%; margin-left: 5%"
					:loading="phoneVeriCodeNextTime > 0"
					@click="getRegCellphoneVeriCode">
					{{ phoneVeriCodeNextTime > 0 ? '(' + phoneVeriCodeNextTime + ')' : '手机验证' }}
				</el-button>
			</el-form-item>
			<el-form-item
				label="邮箱地址"
				prop="email"
				style="width:100%">
				<el-input
					v-model="regData.email"
					clearable
					placeholder="请输入邮箱地址"
					style="width:50%" />
				<el-button
					type="primary"
					style="width:45%; margin-left: 5%"
					:loading="emailVeriCodeNextTime > 0"
					@click="getRegEmailVeriCode">
					{{ emailVeriCodeNextTime > 0 ? '(' + emailVeriCodeNextTime + ')' : '邮箱验证' }}
				</el-button>
			</el-form-item>
			<el-form-item
				label="手机验证码"
				prop="phoneVeriCode"
				style="width:100%">
				<el-input
					v-model="regData.phoneVeriCode"
					clearable
					placeholder="请输入手机验证码"
					style="width:100%" />
			</el-form-item>
            <el-form-item
				label="邮箱验证码"
				prop="emailVeriCode"
				style="width:100%">
				<el-input
					v-model="regData.emailVeriCode"
					clearable
					placeholder="请输入邮箱验证码"
					style="width:100%" />
			</el-form-item>
			<el-form-item
				label="密码"
				prop="password1"
				style="width:100%">
				<el-input
					v-model="regData.password1"
					show-password
					clearable
					placeholder="请输入密码"
					style="width:100%" />
			</el-form-item>
			<el-form-item
				label="重复密码"
				prop="password2"
				style="width:100%">
				<el-input
					v-model="regData.password2"
					show-password
					clearable
					placeholder="请重复密码"
					style="width:100%" />
			</el-form-item>
		</el-form>
		<el-row style="margin-top: 20px; margin-bottom: 20px;">
			<el-button
				type="text"
				style="margin-left: 50px; float: right;"
				@click="onBackToLoginBtnClk">
				返回登录
			</el-button>
		</el-row>
		<el-row>
			<el-button
				class="loginButton"
				@click="onRegUserBtnClk"
				type="primary">
				注册
			</el-button>
		</el-row>
    </div>
</template>

<script>
import { sha256 } from 'js-sha256'
import {
    getRegEmailVeriCode,
    webRegister
} from '@/api/webRegister'
export default {
    name: 'Register',

    mounted() {
        this.regData = {
            userName: '',
            phone: '',
            email: '',
            phoneVeriCode: '',
            emailVeriCode: '',
            password1: '',
            password2: '',
        };
    },

    data() {
        var that = this;
        return {
            timerHandle: null,

            // 注册登录框的数据
            regData: {
                userName: '',
                phone: '',
                email: '',
                phoneVeriCode: '',
                emailVeriCode: '',
                password1: '',
                password2: '',
            },

            // 注册登录框formdata的校验规则
            regRule: {
                userName: [{
                    required: true,
                    message: "用户名不能为空",
                    trigger: "blur"
                }],

                phone: [{
                    required: false,
                    validator: (rule, value, callback) => {
                        if ((null == value) || ('' == value)) {
                            return callback();
                        }

                        var myreg = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
                        if(!myreg.test(value)) {
                            return callback(new Error("手机号码格式非法"));
                        } else {
                            callback();
                        }
                    },
                    trigger: "blur"
                }],

                email: [{
                    required: false,
                    validator: (rule, value, callback) => {
                        if ((null == value) || ('' == value)) {
                            return callback();
                        }

                        if (value.match(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/)) {
                            callback();
                        } else {
                            callback(new Error("邮箱地址格式不对"))
                        }
                    },

                    trigger: "blur"
                }],

                phoneVeriCode: [{
                    required: false,
                    validator: (rule, value, callback ) => {
                        if ((that.regData.phone !== '') && (that.regData.phone != null)) {
                            // 如果验证邮箱不为空，那么邮箱验证码也不能为空
                            if ((value == null) || (value === '')) {
                                return callback(new Error('手机验证码不可为空'));
                            }
                        }
                        callback();
                    },
                    trigger: "blur"
                }],

                emailVeriCode: [{
                    required: false,
                    validator: (rule, value, callback ) => {
                        if ((that.regData.email !== '') && (that.regData.email != null)) {
                            // 如果验证邮箱不为空，那么邮箱验证码也不能为空
                            if ((value == null) || (value === '')) {
                                return callback(new Error('邮箱验证码不可为空'));
                            }
                        }
                        callback();
                    },
                    trigger: "blur"
                }],

                password1: [{
                    required: true,
                    message: "密码不能为空",
                    trigger: "blur"
                }],

                password2: [{
                    required: true,
                    validator: (rule, value, callback ) => {
                        if ((null == value) || ('' == value)) {
                            return callback(new Error("请再次输入密码"));
                        }

                        if (that.regData.password1 != that.regData.password2) {
                            return callback(new Error("两次输入的密码不一致"));
                        }

                        callback();
                    },
                    trigger: "blur" 
                }],
            },
            
            phoneVeriCodeNextTime: 0, // 手机验证码剩余时间
            emailVeriCodeNextTime: 0, // 邮箱验证码剩余时间
        }
    },

    mounted() {
        this.regData = {
            userName: '',
            phone: '',
            email: '',
            phoneVeriCode: '',
            emailVeriCode: '',
            password1: '',
            password2: '',
        };
        this.timerHandle = setInterval(() => {
            if (this.phoneVeriCodeNextTime > 0) {
                this.phoneVeriCodeNextTime -= 1; // 如果手机验证码的时间不是0，那么手机验证码的剩余时间-1s
            }
            if (this.emailVeriCodeNextTime > 0) {
                this.emailVeriCodeNextTime -= 1; // 如果邮箱验证码的时间不是0，那么邮箱验证码的剩余时间-1s
            }
        }, 1000)
    },

    beforeRouteLeave() {
        clearInterval(this.timerHandle);
    },

    methods: {
        getRegCellphoneVeriCode() {
            this.$message({
                message: '目前不支持手机验证功能',
                type: "error",
                duration: 3 * 1000
            })
            // 获取手机验证码
            this.regData.verificateMode = 'cellphone';
            this.phoneVeriCodeNextTime = 120;
        },

        getRegEmailVeriCode() {
            // 检查邮箱格式是否合法
            this.$refs['regForm'].validateField('email', valid => {
                if ('' !== valid) {
                    return;
                }
                if (('' === this.regData.email) || (null === this.regData.email)) {
                    this.$message({
                        message: '邮箱地址不能为空',
                        type: "error",
                        duration: 3 * 1000
                    });
                    return;
                }

                // 获取邮箱验证码
                getRegEmailVeriCode(this.regData.email).then((res) => {
                    this.$message({
                        message: res.data,
                        type: "success",
                        duration: 3 * 1000
                    })
                    // 如果邮箱格式合法，更新定时
                    this.regData.verificateMode = 'email';
                    this.emailVeriCodeNextTime = 120;
                }, (res) => {
                    this.$message({
                        message: res.msg,
                        type: "error",
                        duration: 3 * 1000
                    })
                })
            });
        },

        onBackToLoginBtnClk() {
            this.$emit('modeChanged', 'login');
        },

        onRegUserBtnClk() {
            this.$refs["regForm"].validate(valid => {
                if (!valid) {
                    return;
                }
                // 邮箱和手机至少使能一个
                let enableEmail = ((this.regData.email !== '') && (this.regData.email != null));
                let enablePhone = ((this.regData.phone !== '') && (this.regData.phone != null));
                if ((!enableEmail) && (!enablePhone)) {
                    this.$message({
                        message: '手机验证和邮箱验证至少使能一种',
                        type: "error",
                        duration: 3 * 1000
                    })
                }

                // 将密码转为密文
                let pwd256 = sha256(this.regData.password1);
                this.regData.password = pwd256;
                webRegister(this.regData).then((res) => {
                    this.$message({
                        message: '注册成功',
                        type: "success",
                        duration: 3 * 1000
                    })
                    this.regData = {
                        userName: '',
                        phone: '',
                        email: '',
                        phoneVeriCode: '',
                        emailVeriCode: '',
                        password1: '',
                        password2: '',
                    };
                }, (res) => {
                    this.$message({
                        message: res.msg,
                        type: "error",
                        duration: 3 * 1000
                    })
                    this.password = undefined;
                    this.password1 = '';
                    this.password2 = '';
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.loginButton {
    float: middle;
    width: 80%;
    margin-left: 10%;
    height: 50px;
    border-radius: 25px;
    font-size: 15pt;
}
</style>
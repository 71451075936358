<template>
  <div class="homePage">
    <div class="slidePic">
      <el-carousel
        height="100vh"
        :interval="7000"
        indicator-position="none"
        arrow="never"
      >
        <el-carousel-item>
          <div>
            <img
              class="slideImg"
              src="@/assets/homePage/slideshow1.jpg"
              alt=""
            />
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <img
              class="slideImg"
              src="@/assets/homePage/slideshow2.jpg"
              alt=""
            />
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <img
              class="slideImg"
              src="@/assets/homePage/slideshow3.jpg"
              alt=""
            />
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <img
              class="slideImg"
              src="@/assets/homePage/slideshow4.jpg"
              alt=""
            />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="top-logo">
        <img src="@/assets/homePage/logo.png" alt="" />
      </div>
      <div class="text1-img">
        <img src="@/assets/homePage/text1.png" alt="" />
      </div>
      <div class="text1-info">
        <div class="info-top">综合能源管理系统</div>
        <div class="info-mid">
          是基于智能物联操作系统，采用云端部署+云端运维的产品架构，集成锂电池、BMS、PCS等子系统，根据不同应用场景，为客户提供针对性的系统解决方案
        </div>
        <div class="info-bottom">
          <el-button type="primary" @click="toYPT">点击进入</el-button>
        </div>
      </div>
      <div class="ani-box">
        <div class="ani-top">
          <div class="ani-text ani-text1">
            <div>
              储能3s产品研发及认证；320MW/960MWh内蒙古现代能源风光储一体化示范项目并网。
            </div>
          </div>
          <div class="ani-time ani-time1">
            <div class="time-left">
              <div class="ani-line"></div>
              <div class="ani-cir"></div>
            </div>
            <div class="time-right">2021</div>
          </div>
          <div class="ani-text ani-text2 left-26">
            <div>
              轨道交通行业实现突破； 微网实现农用、户用突破；
              50000平米西青新厂区开工； 3MW产品批量。
            </div>
          </div>
          <div class="ani-time ani-time2">
            <div class="time-left">
              <div class="ani-line"></div>
              <div class="ani-cir"></div>
            </div>
            <div class="time-right">2019</div>
          </div>
          <div class="ani-text ani-text3 left-26">
            <div>中国风电控制企业首个独立出口海外。</div>
          </div>
          <div class="ani-time ani-time3">
            <div class="time-left">
              <div class="ani-line"></div>
              <div class="ani-cir"></div>
            </div>
            <div class="time-right">2016</div>
          </div>
          <div class="ani-text ani-text4 left-26">
            <div>产值突破10亿元； 50000平米空港厂区奠基； 2MW产品批量。</div>
          </div>
          <div class="ani-time ani-time4">
            <div class="time-left">
              <div class="ani-line"></div>
              <div class="ani-cir"></div>
            </div>
            <div class="time-right">2011</div>
          </div>
          <div class="ani-text ani-text5 left-26" style="text-align: right">
            <div>成立瑞源电气。</div>
            <div></div>
          </div>
          <div class="ani-time ani-time5">
            <div class="time-left">
              <div class="ani-line"></div>
              <div class="ani-cir"></div>
            </div>
            <div class="time-right">2008</div>
          </div>
        </div>
        <div class="ani-mid"></div>
        <div class="ani-bottom">
          <div class="ani-text ani-text1">
            <div>年产值实现翻倍增长；中国首个MW级海上平台储能项目签约。</div>
          </div>
          <div class="ani-time ani-time1">
            <div class="time-left">
              <div class="ani-cir"></div>
              <div class="ani-line"></div>
            </div>
            <div class="time-right">2020</div>
          </div>
          <div class="ani-text ani-text2 left-26">
            <div>实验室通过CNAS认证；电力电子行业实现突破。</div>
          </div>
          <div class="ani-time ani-time2">
            <div class="time-left">
              <div class="ani-cir"></div>
              <div class="ani-line"></div>
            </div>
            <div class="time-right">2018</div>
          </div>
          <div class="ani-text ani-text3 left-26">
            <div>空港瑞源厂区正式投产；产品应用突破10000台。</div>
          </div>
          <div class="ani-time ani-time3">
            <div class="time-left">
              <div class="ani-cir"></div>
              <div class="ani-line"></div>
            </div>
            <div class="time-right">2014</div>
          </div>
          <div class="ani-text ani-text4 left-26">
            <div>迁入10000m²西青厂区； 1.5MW产品批量。</div>
          </div>
          <div class="ani-time ani-time4">
            <div class="time-left">
              <div class="ani-cir"></div>
              <div class="ani-line"></div>
            </div>
            <div class="time-right">2009</div>
          </div>
        </div>
      </div>
      <div class="text2-img">
        <img src="@/assets/homePage/text2.png" alt="" />
      </div>
      <div class="text2-info">
        <div class="info-top">高效+智能</div>
        <div class="info-mid">
          实时关注各产品设备自身运行状态、环境参数以及电网信息，确保产品稳定运行；通过综合能源管理系统为客户提供快捷高效的设备运维
        </div>
        <div class="info-bottom">
          <img src="@/assets/homePage/QRcode.png" alt="" />
        </div>
      </div>
      <div class="text2-effect">
        <img src="@/assets/homePage/displayEffect.png" alt="" />
      </div>
      <div class="text3-img">
        <img src="@/assets/homePage/text3.png" alt="" />
      </div>
      <div class="text3-info">
        <div class="info-top">可靠+专业</div>
        <div class="info-mid">
          实时关注各产品设备自身运行状态、环境参数以及电网信息，确保产品稳定运行；通过综合能源管理系统为客户提供快捷高效的设备运维
        </div>
      </div>
      <div class="text3-display">
        <div class="display-img">
          <div>
            <img src="@/assets/homePage/bottomPic1.png" alt="" />
          </div>
          <div>
            <img src="@/assets/homePage/bottomPic2.png" alt="" />
          </div>
          <div>
            <img src="@/assets/homePage/bottomPic3.png" alt="" />
          </div>
          <div>
            <img src="@/assets/homePage/bottomPic4.png" alt="" />
          </div>
        </div>
        <div class="display-text">
          <div class="display-box">
            <div class="display-title">每年帮助清洁发电</div>
            <div class="display-des">
              <div>500</div>
              <div>亿度</div>
            </div>
          </div>
          <div class="display-box">
            <div class="display-title">全球累计应用产品</div>
            <div class="display-des">
              <div class="des-text1">30,000</div>
              <div>台</div>
            </div>
          </div>
          <div class="display-box">
            <div class="display-title">储能设备年产能</div>
            <div class="display-des">
              <div class="des-text2">1GW+</div>
              <div></div>
            </div>
          </div>
          <div class="display-box">
            <div class="display-title">自主研发</div>
            <div class="display-des">
              <div class="des-text3">14</div>
              <div>年</div>
            </div>
          </div>
        </div>
      </div>

      <div class="display-bottom">
        <a href="https://beian.miit.gov.cn/" target="_blank" class="record"
          >Copyright&nbsp;©&nbsp;2024&nbsp;天津瑞源电气有限公司&nbsp;
          津ICP备12000015号-1
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toYPT() {
      window.location.href = "https://mg.relectric.cn/bcp";
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.homePage {
  overflow: hidden;

  .slidePic {
    // // background: url("@/assets/homePage/slideshow1.jpg") no-repeat;
    // background-size: 100% 100%;
    // background-position: 0 0;
    // transition: 1s all 1s;
    animation: myBreath 10s ease-in-out infinite;
    .slideImg {
      width: 100%;
      height: 100vh;
    }
  }
  @keyframes myBreath {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .content::-webkit-scrollbar {
    width: 8px;
  }
  .content::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
  }
  .content::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(255, 255, 255, 0.1);
  }
  .content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

    .top-logo {
      position: absolute;
      left: 70px;
      top: 86px;
    }
    .text1-img {
      position: absolute;
      right: 178px;
      top: 200px;
    }
    .text1-info {
      position: absolute;
      width: 1310px;
      height: 476px;
      right: 178px;
      top: 200px;
      .info-top {
        color: rgba(255, 255, 255, 1);
        font-size: 72px;
        text-align: right;
        margin-top: 98px;
        margin-right: 45px;
        margin-bottom: 45px;
      }
      .info-mid {
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: right;
        width: 712px;
        margin-left: 553px;
        margin-bottom: 54px;
      }
      .info-bottom {
        margin-left: 909px;
        width: 246px;
        height: 60px;
        .el-button {
          width: 246px;
          height: 60px;
        }
      }
    }
    .ani-box {
      width: 100%;
      position: absolute;
      top: 660px;
      .ani-top {
        display: flex;
        position: absolute;
        z-index: 200;
        left: 100px;
        .ani-text {
          width: 208px;
          height: 113px;
          transform: scale(0);
          text-align: right;
          line-height: 25px;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: right;
          padding-right: 10px;
        }
        .ani-text1 {
          animation: aniTopLeft 1s linear 2s forwards;
        }
        .ani-text2 {
          animation: aniTopLeft 1s linear 6s forwards;
        }
        .ani-text3 {
          animation: aniTopLeft 1s linear 10s forwards;
        }
        .ani-text4 {
          animation: aniTopLeft 1s linear 14s forwards;
        }
        .ani-text5 {
          animation: aniTopLeft 1s linear 18s forwards;
        }
        .left-26 {
          margin-left: 36px;
        }
        @keyframes aniTopLeft {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
        @keyframes aniTopRight {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .ani-time {
          display: flex;
          opacity: 0;
          .time-left {
            .ani-line {
              height: 110px;
              width: 1px;
              border-left: 1px solid #409eff;
              margin-left: 5px;
            }
            .ani-cir {
              width: 12px;
              height: 12px;
              border: 1px solid rgba(64, 158, 255, 1);
              border-radius: 50%;
              background-color: rgba(255, 255, 255, 1);
            }
          }
          .time-right {
            font-size: 36px;
            color: rgba(64, 158, 255, 1);
            line-height: 110px;
            margin-left: 8px;
          }
        }
        .ani-time1 {
          animation: aniTopRight 1s linear 1s forwards;
        }
        .ani-time2 {
          animation: aniTopRight 1s linear 5s forwards;
        }
        .ani-time3 {
          animation: aniTopRight 1s linear 9s forwards;
        }
        .ani-time4 {
          animation: aniTopRight 1s linear 13s forwards;
        }
        .ani-time5 {
          animation: aniTopRight 1s linear 17s forwards;
        }
      }
      .ani-mid {
        position: absolute;
        top: 116px;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: 0%;
        border-bottom: 1px solid #409eff;
        animation: aniLine 1s linear forwards;
      }
      @keyframes aniLine {
        0% {
          width: 0%;
        }
        100% {
          width: 95%;
        }
      }
      .ani-bottom {
        display: flex;
        position: absolute;
        z-index: 200;
        left: 270px;
        top: 111px;
        .ani-text {
          width: 208px;
          height: 113px;
          transform: scale(0);
          text-align: right;
          line-height: 25px;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          display: flex;
          align-items: center;
          padding-right: 10px;
        }
        .ani-text1 {
          animation: aniTopLeft 1s linear 4s forwards;
        }
        .ani-text2 {
          animation: aniTopLeft 1s linear 8s forwards;
        }
        .ani-text3 {
          animation: aniTopLeft 1s linear 12s forwards;
        }
        .ani-text4 {
          animation: aniTopLeft 1s linear 16s forwards;
        }
        .left-26 {
          margin-left: 36px;
        }
        @keyframes aniTopLeft {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
        @keyframes aniTopRight {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .ani-time {
          display: flex;
          opacity: 0;
          .time-left {
            .ani-line {
              height: 110px;
              width: 1px;
              border-left: 1px solid #409eff;
              margin-left: 5px;
            }
            .ani-cir {
              width: 12px;
              height: 12px;
              border: 1px solid rgba(64, 158, 255, 1);
              border-radius: 50%;
              background-color: rgba(255, 255, 255, 1);
            }
          }
          .time-right {
            font-size: 36px;
            color: rgba(64, 158, 255, 1);
            line-height: 110px;
            margin-left: 8px;
          }
        }
        .ani-time1 {
          animation: aniTopRight 1s linear 3s forwards;
        }
        .ani-time2 {
          animation: aniTopRight 1s linear 7s forwards;
        }
        .ani-time3 {
          animation: aniTopRight 1s linear 11s forwards;
        }
        .ani-time4 {
          animation: aniTopRight 1s linear 15s forwards;
        }
      }
    }
    .text2-img {
      position: absolute;
      right: 178px;
      top: 1300px;
    }
    .text2-info {
      position: absolute;
      width: 1310px;
      height: 476px;
      right: 178px;
      top: 1300px;
      .info-top {
        color: rgba(255, 255, 255, 1);
        font-size: 72px;
        text-align: right;
        margin-top: 98px;
        margin-right: 45px;
        margin-bottom: 45px;
      }
      .info-mid {
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: right;
        width: 712px;
        margin-left: 553px;
        margin-bottom: 54px;
      }
      .info-bottom {
        // text-align: right;
        margin-left: 1114px;
        width: 150px;
        height: 150px;
      }
    }
    .text2-effect {
      width: 909px;
      height: 578px;
      position: absolute;
      left: 121px;
      top: 1280px;
    }
    .text3-img {
      position: absolute;
      right: 178px;
      top: 2400px;
    }
    .text3-info {
      position: absolute;
      width: 1310px;
      height: 476px;
      right: 178px;
      top: 2400px;
      .info-top {
        color: rgba(255, 255, 255, 1);
        font-size: 72px;
        text-align: right;
        margin-top: 98px;
        margin-right: 45px;
        margin-bottom: 45px;
      }
      .info-mid {
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: right;
        width: 712px;
        margin-left: 553px;
        margin-bottom: 54px;
      }
    }
    .text3-display {
      width: 1536px;
      height: 476px;
      position: absolute;
      left: 170px;
      top: 2800px;
      background-color: rgba(255, 255, 255, 1);
      .display-img {
        display: flex;
        justify-content: space-between;
        margin: 19px 28px 64px;
      }
      .display-text {
        display: flex;
        justify-content: space-between;
        margin: 0 28px;
        .display-box {
          width: 348px;
          padding-left: 37px;
          text-align: left;
          .display-title {
            margin-bottom: 21px;
          }
          .display-des {
            padding-left: 17px;
            display: flex;
            height: 53px;
            line-height: 53px;
            :first-child {
              color: rgba(64, 158, 255, 1);
              font-size: 48px;
              margin-right: 12px;
            }
            .des-text1 {
              color: rgba(64, 84, 255, 1);
            }
            .des-text2 {
              color: rgba(114, 64, 255, 1);
            }
            .des-text3 {
              color: rgba(55, 47, 194, 1);
            }
          }
        }
      }
    }
    .display-bottom {
      height: 1px;
      width: 100%;
      position: absolute;
      border-top:1px solid  rgba(255, 255, 255, 0.3);
      padding-top: 10px;
      top: 3326px;
      .record {
        padding-bottom: 15px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
</style>

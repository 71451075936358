import request from '@/utils/request'
// 获取组织
export function getAllAccessableOrgs() {
    return request({
      url: '/api/org/getAllAccessableOrgs',
      method: 'post',
      data:''
    })
}
// 获取项目
export function getAllAccessablePjt4Map() {
  return request({
    url: '/api/project/getAllAccessablePjt4Map',
    method: 'post',
    data:''
  })
}

// 后台设置组织id 的cookie
export function setOrg(orgId) {
    return request({
      url: '/api/WebContext/setOrg',
      method: 'post',
      data:{orgId}
    })
}
// 后台设置项目id 的cookie
export function setProject(projectId) {
    return request({
        url: '/api/WebContext/setProject',
        method: 'post',
        data:{projectId}
    })
}
import Cookies from 'js-cookie'

const TokenKey = 'Token'// 用户token 键名
const UsernameKey = "userName";// 用户名 键名
const UserIdKey = "userId"// 用户Id 键名


const OrgIdKey = "orgId"// 组织Id 键名
const OrgNameKey = "orgName"// 组织名 键名

const RoleIdKey = "roleId"// 角色Id 键名

const ProjectListKey = 'projectList' // 项目列表 键名
const ResourceListKey = 'resourceList' // 资源列表 键名
const OrganizationListKey = 'organizationList' // 组织列表 键名
// 获取Token的方法
export function getToken() {
  return Cookies.get(TokenKey)
}

// 装载Token的方法
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

// 删除Token的方法
export function removeToken() {
  return Cookies.remove(TokenKey)
}



// 获取userName的方法
export function getUsername() {
  return Cookies.get(UsernameKey)
}

// 装载userName的方法
export function setUsername(userName) {
  return Cookies.set(UsernameKey, userName)
}

// 删除userName的方法
export function removeUsername() {
  return Cookies.remove(UsernameKey)
}



// 获取UserId的方法
export function getUserId() {
  return Cookies.get(UserIdKey)
}

// 装载UserId的方法
export function setUserId(userId) {
  return Cookies.set(UserIdKey, userId)
}

// 删除UserId的方法
export function removeUserId() {
  return Cookies.remove(UserIdKey)
}



// 获取OrgIdKey的方法
export function getOrgId() {
  return Cookies.get(OrgIdKey)
}

// 装载OrgIdKey的方法
export function setOrgId(orgId) {
  return Cookies.set(OrgIdKey, orgId)
}

// 删除OrgIdKey的方法
export function removeOrgId() {
  return Cookies.remove(OrgIdKey)
}



// 获取orgName的方法
export function getOrgName() {
  return Cookies.get(OrgNameKey)
}

// 装载orgName的方法
export function setOrgName(orgName) {
  return Cookies.set(OrgNameKey, orgName)
}

// 删除orgName的方法
export function removeOrgName() {
  return Cookies.remove(OrgNameKey)
}



// 获取roleId的方法
export function getRoleId() {
  return Cookies.get(RoleIdKey)
}

// 装载roleId的方法
export function setRoleId(roleId) {
  return Cookies.set(RoleIdKey, roleId)
}

// 删除roleId的方法
export function removeRoleId() {
  return Cookies.remove(RoleIdKey)
}



// 获取projectList的方法
export function getProjectList() {
  return Cookies.get(ProjectListKey)
}

// 装载projectList的方法
export function setProjectList(projectList) {
  return Cookies.set(ProjectListKey, JSON.stringify(projectList))
}

// 删除projectList的方法
export function removeProjectList() {
  return Cookies.remove(ProjectListKey)
}

// 获取resourceList的方法
export function getResourceList() {
  return Cookies.get(ResourceListKey)
}

// 装载resourceList的方法
export function setResourceList(resourceList) {
  return Cookies.set(ResourceListKey, JSON.stringify(resourceList))
}

// 删除projectList的方法
export function removeResourceList() {
  return Cookies.remove(ResourceListKey)
}




// 获取OrganizationList的方法
export function getOrganizationList(){
  return Cookies.get(OrganizationListKey)
}
// 装载OrganizationList的方法
export function setOrganizationList(organizationList){
  return Cookies.set(OrganizationListKey, JSON.stringify(organizationList))
}
// 删除OrganizationList的方法
export function removeOrganizationList(){
  return Cookies.remove(OrganizationListKey)
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import map from './map/map.js'
import "./permission1"; // permission control 访问权限控制

import "@/styles/index.less"; // global css
//将map.js挂载到vue上
Vue.prototype.$map = map
Vue.config.productionTip = false
import 'element-ui/lib/theme-chalk/index.css';
import { Table,TableColumn,Container,Header,Main,Aside,Footer,Menu,Submenu, MenuItemGroup,MenuItem,Form,FormItem,Input,Button,Select,Option,Row,Tabs,TabPane,Checkbox,
         Carousel,CarouselItem,Pagination,DatePicker,Tree,Tag,Tooltip,Message,Loading} from 'element-ui';
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Container.name, Container);
Vue.component(Header.name, Header);
Vue.component(Main.name, Main);
Vue.component(Aside.name, Aside);
Vue.component(Footer.name, Footer);
Vue.component(Menu.name, Menu);
Vue.component(Submenu.name, Submenu);
Vue.component(MenuItemGroup.name, MenuItemGroup);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Input.name, Input);
Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(Row.name, Row);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Pagination.name, Pagination);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Tree.name, Tree);
Vue.component(Tag.name, Tag);
Vue.component(Tooltip.name, Tooltip);
Vue.use(Loading)
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)

Vue.prototype.$message = Message;

import * as echarts from "echarts"
Vue.prototype.$echarts = echarts

import echartsGL from 'echarts-gl' // 引入echarts
 Vue.prototype.$echartsGL = echartsGL // 引入组件（将echarts注册为全局）

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

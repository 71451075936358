import request from '@/utils/request'

// loginName：登录名(邮箱或手机)
// pwd：密码原始密码经过sha256加密后的密文
export function loginByPwd(loginName, pwd) {
    return request({
        url: '/WebLogin/LoginByPwd',
        method: 'post',
        data: {
            'loginName': loginName,
            'password': pwd
        }
    })
}

// 
export function loginByVeriCode(veriMode, veriCode, loginName) {
    let data = {
        'veriMode': veriMode,
        'veriCode': veriCode,
    }
    if (veriMode === 'email') {
        data.email = loginName;
    }
    if (veriMode === 'phone') {
        data.phone = loginName;
    }

    return request({
        url: '/WebLogin/LoginByVeriCode',
        method: 'post',
        data
    })
}

export function getEmailLoginVeriCode(email) {
    return request({
        url: '/WebLogin/GetEmailVeriCode',
        method: 'post',
        data: {
            'email': email
        }
    })
}
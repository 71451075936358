import request from '@/utils/request'

export function getRegEmailVeriCode(email) {
    return request({
        url: '/WebRegister/GetEmailVeriCode',
        method: 'post',
        data: {
            'email': email
        }
    })
}

export function webRegister(data) {
    return request({
        url: '/WebRegister/Register',
        method: 'post',
        data
    })
}
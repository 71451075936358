<template>
   <div class="outer">
        <div class="navbar">
            <div class="left">
                <span>{{date}}</span>
                <span>{{week}}</span>
                <!-- <span>多云</span> -->
            </div>
            <div class="center title gradient-text-one">
                <span class="title">储能智慧能源平台</span>
            </div>
            <div class="right">
                <span v-show="isGlobalUser === false">组织名称：</span>
                <select v-show="isGlobalUser === false" v-model="orgvalue" class="org_select" @change="handleChange()">
                    <option v-for="item in orgoptions" :key="item.orgId" :value="item.orgId">{{item.displayName}}</option>
                </select>
                <!-- <img src="../assets/main/user.png" alt=""> -->
                <a @click="logOut"><img src="../assets/main/exit.png" alt=""/></a>
            </div>
        </div>
        <div class="mainContent">
            <div class="left">
                <div class="top">
                    <!-- 数据区域 -->
                    <div class="data">
                        <div>
                            <span class="count">{{bessPower}}</span>
                            <span class="name">储能装机功率/kW</span>
                        </div>
                        <div>
                            <span class="count">{{bessEnergy}}</span>
                            <span class="name">储能装机容量/kWh</span>
                        </div>
                        <div>
                            <span class="count">{{totalDischarge}}</span>
                            <span class="name">累计充电量/kWh</span>
                        </div>
                        <div>
                            <span class="count">{{totalCharge}}</span>
                            <span class="name">累计放电量/kWh</span>
                        </div>
                        <div>
                            <span class="count">{{rtPower}}</span>
                            <span class="name">实时总功率/kW</span>
                        </div>
                        <div>
                            <span class="count">{{onlineProjectCnt}}</span>
                            <span class="name">项目在线数量</span>
                        </div>
                    </div>
                    <!-- 地图区域 -->
                    <div class="map_outer">
                        <div class="map"  id="maps" v-if="showMap"></div>
                        <map1 v-else :orgvalue="orgvalue" ref="map1" @getData6="getData6"></map1>
                    </div>
                </div>
                <div class="bottom">
                    <div class="card">
                        <div class="card-in">
                            <div class="card-title">月充放电量</div>
                            <div class="card-content">
                                <MonthCrbar :monthcharges="monthcharges" :monthreleases="monthreleases"/>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-in">
                            <div class="card-title">生态效益</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="card">
                    <div class="card-in">
                        <div class="card-title">项目状态占比</div>
                        <div class="card-content">
                            <pie :pjtStateSeriesData="pjtStateSeriesData" :onlineProjects="onlineProjects" :offProjects="offProjects"/>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-in">
                        <div class="card-title">项目实时状态</div>
                        <div class="card-content">
                            <div class="item_state_head">
                                <div>项目名称</div>
                                <div>实时状态</div>
                            </div>
                            <div class="item_state_content">
                                <ul>
                                    <li v-for="(item,index) in projectFaultStateDes" :key="item.projectId+index">
                                        <div class="pjtName">{{item.projectName}}</div>
                                        <div class="pjtStateDes">{{item.trouble}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-in">
                        <div class="card-title">效率对比</div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-in">
                        <div class="card-title">日充放电量</div>
                        <div class="card-content">
                            <crbar :daycharges="daycharges" :dayreleases="dayreleases"/>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-in">
                        <div class="card-title">收益统计</div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-in">
                        <div class="card-title">电池健康度统计</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Map1 from '@/components/map2.vue';
import * as echarts from 'echarts';
import {getAllAccessableOrgs,getAllAccessablePjt4Map,setOrg,setProject} from '@/api/orgAndProject.js';
import {getWebContext} from '@/api/webContext.js';
import {getActualTrouble,getStatisticsData} from '@/api/statistics';
import pie from '@/components/main/pie.vue'
import crbar from '@/components/main/dayCRBar.vue'
import MonthCrbar from '@/components/main/monthCRBar.vue'

export default {
    data(){
      return{
        orgoptions: [],// 组织下拉选项数组
        orgvalue: '',
        timer:'',// 左上角时间Interval对象
        date:'',// 左上角时间日期
        week:'',//星期几

        /**地图上方的6个数据 */
        bessPower:0,//储能装机功率/kW
        bessEnergy:0,// 储能装机容量/kWh
        totalDischarge:0,// 累计放电量
        totalCharge:0,//累计充电量
        rtPower:0,//实时总功率
        onlineProjectCnt:0,// 项目在线数量
    
        map: null,
        layers: null,
        showMap:false,
        mapList: [{
            id: 1,
            name: '智图地图',
            url: 'Geoq.Normal.Map'
          },
          {
            id: 4,
            name: '天地影像',
            url: 'TianDiTu.Satellite.Map'
          },
          {
            id: 5,
            name: '午夜蓝',
            url: 'Geoq.Normal.PurplishBlue'
          },
          {
            id: 6,
            name: '高德地图',
            url: 'GaoDe.Satellite.Map'
          }
        ],
        // mapUrl: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        // mapUrl: "http://webrd01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}",
        mapUrl: 'Geoq.Normal.PurplishBlue',
        options: {  //地图基本功能配置项
          minZoom: 0,
          maxZoom: 20,
          //center: [39.125596, 117.190182],
          center: [41.8, 123.38],
          zoom: 3,
          minZoom: 3,
          maxZoom: 18,
          zoomControl: false, //禁用 + - 按钮
          doubleClickZoom: true, // 禁用双击放大
          attributionControl: false, // 移除右下角leaflet标识
          dragging: true, //禁止鼠标拖动滚动
          boxZoom: true, //决定地图是否可被缩放到鼠标拖拽出的矩形的视图，鼠标拖拽时需要同时按住shift键.
          scrollWheelZoom: true, //禁止鼠标滚动缩放
        },
        districts: null,
        isGlobalUser: false, // 是否为全局用户，如果是全局用户就不显示组织下拉菜单

        projectFaultStateDes:[], // 项目实时状态列表
        pjtStateSeriesData:[],// 项目状态占比饼图数据
        onlineProjects:[], // 在线项目信息
        offProjects:[], // 离线项目信息
        daycharges:[], // 项目当日日充量
        daychargesum:0, // 日充总量
        dayreleases:[], // 项目当日日放量
        dayreleasesum:0,// 日放总量
        monthcharge:0, // 月充总量
        monthrelease:0,// 月放总量
        monthcharges:[], // 项目月充量
        monthreleases:[],// 项目月放量
      }
    },
    components:{
        Map1,
        pie,
        crbar,
        MonthCrbar
    },
    methods:{
        //生成地图实例
        getMap() {
            this.map = this.$map.newMap('maps', this.options) //地图容器id与基本配置
            // this.$map.createLayers(this.map, this.mapUrl, this.option) //引入地图图层
            this.layers = this.$map.chinaLayers(this.map, this.mapUrl) //加载各种地图
            // this.map.pm.setLang('zh'); //设置语言  en, de, it, ru, ro, es, fr, pt_br, zh , nl
            // this.map.pm.addControls(this.option) //添加工具
        },
        //动态切换地图
        changeMap(map) {
            this.mapUrl = map
            this.map.removeLayer(this.layers)
            this.$nextTick(() => {
                this.layers = this.$map.chinaLayers(this.map, this.mapUrl)
            })
        },
        // 地图定位相关
        mapPoint(){
                var that = this;
                var greenIcon = L.icon({
                iconUrl: require('../assets/login/leaf-green.png'),
                shadowUrl: require('../assets/login/leaf-shadow.png'),
                iconSize:     [38, 95], // size of the icon
                shadowSize:   [50, 64], // size of the shadow
                iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
                shadowAnchor: [4, 62],  // the same for the shadow
                popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
                });
                L.marker([41.8, 123.38], {icon: greenIcon}).addTo(this.map).on('click',function(e){
                that.$router.push('/manage/system')
                });
        },
        getCurrentTime(){
			var date = new Date();
			var year = date.getFullYear();
			var month = date.getMonth()+1;
			var day = date.getDate();
			var hour = date.getHours()<10?'0'+(''+date.getHours()):date.getHours();
			var minutes = date.getMinutes()<10?'0'+(''+date.getMinutes()):date.getMinutes();
			var seconds = date.getSeconds()<10?'0'+(''+date.getSeconds()):date.getSeconds();

            var weekNum = date.getDay();
            switch(weekNum){
                case 0:
                this.week = '星期日';
                break;
                case 1:
                this.week = '星期一';
                break;
                case 2:
                this.week = '星期二';
                break;
                case 3:
                this.week = '星期三';
                break;
                case 4:
                this.week = '星期四';
                break;
                case 5:
                this.week = '星期五';
                break;
                case 6:
                this.week = '星期六';
                break;
            }

			return `${year}年${month}月${day}日 ${hour}:${minutes}:${seconds}`;
		},
        logOut(){
            this.$store.dispatch('user/resetToken');
            this.$router.replace('/login')
        },
        async setOrgoptions(){
            const {code,msg,data} = await getAllAccessableOrgs();
            if(code==1){
                this.orgoptions = data
                this.orgvalue = this.orgoptions[0].orgId
            }else{
                this.$message({
                    message:'获取组织信息出错'+(msg?'':msg),
                    type:'warning',
                    duration: 1000
                })
            }
            
        },
        handleChange(){
            setOrg(this.orgvalue).then(res=>{
                // 等后端set组织成功后再重新调用获取项目的接口
                this.$refs.map1.orgId = this.orgvalue
                this.$refs.map1.getProjectList()
            })
            
        },
        getData6(val){// 获取地图上方的6个数据中的前两个数据
            this.bessPower = val.bessPower,
            this.bessEnergy = val.bessEnergy
            // this.totalDischarge = val.totalDischarge
            // this.totalCharge = val.totalCharge
            this.rtPower = val.rtPower
            // this.onlineProjectCnt = val.onlineProjectCnt
        },
        // 获取项目实时状态
        getActualTrouble(){
            getActualTrouble().then(res=>{
                const data = res.data
                data.forEach(value => {
                    const {troubleList,projectName,projectId} = value
                    troubleList.forEach(value=>{
                        this.projectFaultStateDes.push({trouble:value,projectName,projectId})
                    })
                    
                });
            })
        },
        // 获取其他统计数据
        getStatisticsData(){
            getStatisticsData().then(res=>{
                console.log(res)
                const {data} = res
                this.totalDischarge = data.totalrelease // 累计放电量
                this.totalCharge = data.totalcharge // 累计充电量
                // this.rtPower = data.power //实时总功率
                this.onlineProjectCnt = data.projectonlinecount // 项目在线数

                this.pjtStateSeriesData = [{name:'在线项目',value:data.onlinestatuscount},{name:'离线项目',value:data.offlinestatuscount}]
                this. onlineProjects = data.onlineProjects// 在线项目信息
                this. offProjects = data.offProjects//离线项目信息

                this.daycharges = data.daycharges // 项目日充量
                this.daychargesum = data.daychargesum // 日充总量
                this.dayreleases = data.dayreleases // 项目日放量
                this.dayreleasesum = data.dayreleasesum // 日放总量

                this.monthcharge = data.monthcharge // 月充总量（所有项目当月总和）
                this.monthrelease = data.monthrelease // 月放总量（所有项目当月总和）

                this.monthcharges = data.monthcharges // 项目月充量
                this.monthreleases = data.monthreleases // 项目月放量
            })
            // this. onlineProjects = [{projectId:1,displayName:'亿利光储充示范项目'},{projectId:2,displayName:'瑞源光储充综合能源项目'}]
            //     this. offProjects = [{projectId:3,displayName:'永靖能源项目'},{projectId:4,displayName:'中广核进贤能源项目'}]
            // this.daycharges = [{projectId:1,displayName:'亿利光储充示范项目',daycharge:155},{projectId:2,displayName:'瑞源光储充综合能源项目',daycharge:100},{projectId:3,displayName:'永靖能源项目',daycharge:223},{projectId:4,displayName:'中广核进贤能源项目',daycharge:343}]
            // this.daychargesum = 255
            // this.dayreleases = [{projectId:1,displayName:'亿利光储充示范项目',dayrelease:155},{projectId:2,displayName:'瑞源光储充综合能源项目',dayrelease:100},{projectId:3,displayName:'永靖能源项目',dayrelease:223},{projectId:4,displayName:'中广核进贤能源项目',dayrelease:343}]
            // this.dayreleasesum = 566
            // this.monthcharge = 1335.77 // 月充量
            // this.monthrelease = 2345.87 // 月放量
        }

    },
    created(){},
    beforeMount(){
        getWebContext().then((res) => {
            this.isGlobalUser = res.data.isGlobalUser;
            if (res.data.isGlobalUser) {
                // 如果是全局用户，直接获取项目列表
                this.$refs.map1.getProjectList();
            } else {
                // 如果不是全局用户，获取组织列表
                this.setOrgoptions();
            }
        })
        // 获取项目实时状态
        this.getActualTrouble()
        // 获取其他统计数据
        this.getStatisticsData()
    },
    mounted(){
        this.getCurrentTime();
        this.timer = window.setInterval(()=>{
            this.date = this.getCurrentTime();
        },1000)
        
        // this.getMap()
        // this.mapPoint()
    },
    beforeDestory () {
		clearInterval(this.timer);
	}
 }
</script>

<style lang="less" scoped>
.org_select{
    background-color: transparent;
    border: none;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color:#fff;
    option::-ms-expand{
        display: none;
    }
    option{
        background-color: #143776;
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari 和 Chrome */
        appearance:none;
        outline: none;
        border: 0;
    }
    /* --背景色字体颜色--*/
    option:hover{
        color:#ffffff;
        background-color:#34CFEA;
        outline: none;
        border: 0;
    }
}
.outer{
    width: 100%;
    height: 100vh;
    background: url('../assets/main/bgc.png') center/100%;
    display: flex;
    flex-direction: column;
}
.navbar{
    width: 100%;
    height: 4rem;
    background: url('../assets/main/header.png') center/100% no-repeat ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    div{
        width:100%;
        height: 100%;
        flex:1;
    }
    .left{
        font-size: 0.6rem;
        font-weight: 200;
        color:#34CFEA;
        display: flex;
        align-items: center;
        span {
            font-size: 0.65rem;
            display: inline-block;
            margin-right: 1rem;
        }
    }
    .center{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        font-weight: 800;
        &.gradient-text-one{ 
            background-image:-webkit-linear-gradient(bottom,#1c5be0,#A6ECFF,#fff);
            -webkit-background-clip:text;
            -webkit-text-fill-color:transparent;
        }
    }
    .right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span{
            font-size: 0.65rem;
            font-weight: 400;
            color:#34CFEA;
        }
        select{
            font-size: 0.65rem;
            font-weight: 400;
            color:#34CFEA;
        }
        img {
            display: block;
            width: 1.2rem;
            margin-left: 1rem;
        }
        img:nth-child(2) {
            margin-right: 2rem;
        }
    }
}
.mainContent{
    width: 100%;
    flex: 1;
    display: flex;
    padding:0.5rem;
    .left{
        flex:1;
        display: flex;
        flex-direction: column;
        .top{
            display: flex;
            flex-direction: column;
            flex:2;
            .data{
                display: flex;
                justify-content: center;
                justify-items: center;
                div{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    font-size: 1.4rem;
                    color:#E0D329;
                    .name{
                        font-size:0.7rem;
                        color:#fff;
                        font-weight:400;
                        margin-top:0.4rem;
                        font-family: 'SimHei';
                        font-weight: 500;
                    }
                }
                div:nth-child(odd){
                    color:#26B0DF
                };
            }
            .map_outer{
                flex: 1;
                padding:0.5rem;
                .map{
                    width:100%;
                    height: 100%;
                }
            }
        }
        .bottom{
            flex:1;
            display: flex;
            height: 40%;
            .card{
                flex:1;
                height: 100%;
                padding: 0.5rem;
            }
        }
    }
    .right{
        flex:1;
        display: flex;
        flex-wrap:wrap;
        .card{
            flex:50%;
            padding: 0.5rem;
        }
    }
    .card-in{
        color:#fff;
        width: 100%;
        height: 100%;
        background:url('@/assets/main/border.png');
        background-size: 100% 100%;
        font-size: 0.8rem;
        text-align:left;
        display: flex;
        flex-direction: column;
        .card-title{
            width: 50%;
            margin-left:1rem;
            padding-top: 0.05rem;
            font-size: 0.8rem;
            font-weight: 700;
        }
        .card-content{
            width: 100%;
            height: 100%;
            flex: 1;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            .item_state_head{
                position:absolute;
                height:2rem;
                line-height:2rem;
                left:1rem;
                right:1rem;
                display: flex;
                border-bottom:1px solid #11a6f5;
                div{
                    font-size:0.8rem;
                    font-weight: 700;
                    width: 50%;
                    text-align: center;
                }
            }
            .item_state_content{
                position: absolute;
                top:2rem;
                left:1rem;
                right:1rem;
                ul{
                    list-style:none;
                    li{
                        display:flex;
                        border-bottom:1px solid #11a6f5;
                        height: 1.5rem;
                        line-height: 1.5rem;
                        div{
                            font-size:0.75rem;
                            width:50%;
                            text-align:center;
                        }
                        .pjtStateDes{
                            color:red;
                        }
                    }
                }
            }
    
        }
        #content1{
            position: absolute;
            left: 0;
            top:0;
            width: 100%;
            height: 100%;
        }
    }
}
@keyframes move{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-50%);
    }
}
</style>
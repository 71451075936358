import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import { getToken } from "@/utils/auth"; // 这是一个鉴权的方法，getToken是其中一个获取token的方法


const whiteList = ["/login", "/"]; // 白名单数组，数组内的路由可以直接访问

router.beforeEach(async (to, from, next) => {
    // ! 确定用户是否已登录
    const hasToken = getToken();
    if (hasToken) {
            if (to.path === "/login") {
                // * 如果已登录，请重定向到主页
                next({ path: "/main"  });
            } else {
                
                next();
            }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next();
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login?redirect=${to.path}`);
        }
    }
});

router.afterEach(() => {
    
});

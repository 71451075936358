<template>
    <div class="login">
        <div class="header">
            <img
                src="@/assets/index/logo.png"
                class="logo" />
        </div>
        <div class="loginBox">
        <div class="inputArea">
            <p class="title" style="margin-bottom: 10px">综合能源管理系统</p>                 
            <p class="subtitle" style="margin-top: 0; margin-bottom: 50px;">Integrated energy management system</p>
            
            <div class="loginBody">
                <el-tabs 
                    v-show="'login' === mode"
                    v-model="loginMode">
                    <el-tab-pane label="验证码登录" name="VerificationCode">
                        <VeriCodeLogin />
                    </el-tab-pane>
                    <el-tab-pane label="密码登录" name="Password">
                        <PwdLogin 
                            @modeChanged="onModeChanged"/>
                    </el-tab-pane>
                </el-tabs>

                <div v-if="'register' === mode">
                    <Register
                        @modeChanged="onModeChanged"/>
                </div>

                <div v-if="'resetPwd' === mode">
                    <ResetPwd 
                        @modeChanged="onModeChanged"/>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import { sha256 } from 'js-sha256'
import  VeriCodeLogin  from './VeriCodeLogin.vue'
import PwdLogin from './PwdLogin.vue'
import Register from './Register.vue'
import ResetPwd from './ResetPwd.vue'
export default {
    name:'Login',
    components: {
        VeriCodeLogin,
        PwdLogin,
        Register,
        ResetPwd
    },

    data() {
        let that = this;
        return  {
            mode: 'login', // 展示模式，'login'登录模式，'register'注册模式，'resetPwd'忘密找回
            loginMode: 'VerificationCode', // 登录模式下，验证码模式和密码模式的选项
            // rememberPwd: false,

            // 验证登录，验证方式
            verificateModeList: [{
                value: 'cellphone',
                label: '手机验证',
            }, {
                value: 'email',
                label: '邮箱验证',
            }],

            phoneVeriCodeNextTime: 0, // 手机验证码剩余时间
            emailVeriCodeNextTime: 0, // 邮箱验证码剩余时间

            timerHandle: null, //定时器的句柄
        };
    },

    mounted() {
        this.timerHandle = setInterval(() => {
            if (this.phoneVeriCodeNextTime > 0) {
                this.phoneVeriCodeNextTime -= 1; // 如果手机验证码的时间不是0，那么手机验证码的剩余时间-1s
            }
            if (this.emailVeriCodeNextTime > 0) {
                this.emailVeriCodeNextTime -= 1; // 如果邮箱验证码的时间不是0，那么邮箱验证码的剩余时间-1s
            }
        }, 1000)
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.timerHandle);
        next()
    },

    methods: {
        onRegBtnClk() {
            // 点击注册按钮的响应事件
            this.mode = 'register';
            this.$nextTick(() => {
                this.$refs["regForm"].resetFields();
                this.regData = {
                    userName: '',
                    verificateMode: '',
                    cellphoneNum: '',
                    email: '',
                    verificateCode: '',
                    password1: '',
                    password2: '',
                };
            })
        },

        onModeChanged(mode) {
            this.mode = mode;
        },

        onLoginGetPhoneVeriCodeBtnClk() {
            // 获取手机验证码
            // this.phoneVeriCodeNextTime = 120;
            this.$message({
                message: "暂时不支持手机验证功能",
                type: "error",
                duration: 3 * 1000
            })
        },

        onLoginGetEmailVeriCodeBtnClk() {
            // 获取邮箱验证码
            this.$refs['verificateForm'].validateField('email', valid => {
                if ('' !== valid) {
                    return;
                }

                this.$httpRequests.getLoginVeriCodeByEmail({
                    email: this.verificateData.email
                }).then((res) => {
                    this.$message({
                        message: res,
                        type: "success",
                        duration: 3 * 1000
                    })
                    this.emailVeriCodeNextTime = 120;
                }, (error) => {
                    this.$message({
                        message: error,
                        type: "error",
                        duration: 3 * 1000
                    })
                })
            })
        },

        getRegCellphoneVeriCode() {
            // 获取手机验证码
            this.regData.verificateMode = 'cellphone';
            this.phoneVeriCodeNextTime = 120;
        },

        getRegEmailVeriCode() {
            // 检查邮箱格式是否合法
            this.$refs['regForm'].validateField('email', valid => {
                if ('' !== valid) {
                    return;
                }
                // 获取邮箱验证码
                getRegVerifyCodeByEmail({
                    email: this.regData.email
                }).then((res) => {
                    this.$message({
                        message: res.data,
                        type: "success",
                        duration: 3 * 1000
                    })
                    // 如果邮箱格式合法，更新定时
                    this.regData.verificateMode = 'email';
                    this.emailVeriCodeNextTime = 120;
                }, (res) => {
                    this.$message({
                        message: res,
                        type: "error",
                        duration: 3 * 1000
                    })
                })
            });
        }
    },
}
</script>

<style lang="less" scoped>
.login {
    width: 100%;
    height: 100%;
    background: url('../../assets/main/bgc.png') center/100%;
    display: flex;
    flex-direction: column;
    .header{
        flex:1;
    }
    .logo {
        width: 13rem;
        float: left;
        margin: 2rem 0 0 2rem
    }
.loginBox{
    flex:4;
    display: flex;
    align-items: baseline;
    justify-content: center;
}
    .inputArea {
        .title {
            font-size: 20pt;
            color: white;
            font-family: "Microsoft YaHei";
            font-weight: bolder;
            width: 100%;
            text-align: center;
        }
        .subtitle {
            font-size: 12pt;
            color: white;
            font-family: "Microsoft YaHei";
            width: 100%;
            text-align: center;
        }
        width: 500px;

        .loginBody {
            color: #fff;
            background: hsla(0, 0%, 100%, 0.2);;
            padding: 20px 40px 40px 40px;
            
        }

        .formTitle {
            font-size: 15pt;
            margin: 20px 0 20px 20px;
        }


    }
}
/deep/ .el-tabs__item{
    color:rgba(255, 255, 255, 0.55)
}
/deep/ .el-form-item__label {
    color:rgba(255, 255, 255, 0.55)
}
/deep/ .el-checkbox {
    color:rgba(255, 255, 255, 0.55)
}
</style>

<template>
  <div id="content1"></div>
</template>

<script>
  export default {
    name:'pie',
    data(){
      return{
        allProjectCount:0,// 项目总数
        seriesData:this.pjtStateSeriesData,
        onPjts:this.onlineProjects,
        offPjts:this.offProjects,
      }
    },
    props:{
        pjtStateSeriesData:{
            type:Array
        },
        onlineProjects:{ // 在线项目信息
            type:Array
        },
        offProjects:{ // 离线项目信息
            type:Array
        }
    },
    computed:{
        onPjtsHTML(){
            let htmlStr = '<p>在线项目：'+this.onPjts.length+'个</p>';
            this.onPjts.forEach((value,index)=>{
                htmlStr +=`<p>${index+1}、${value.displayName}</p>`
            })
            return htmlStr;
        },
        offPjtsHTML(){
            let htmlStr = '<p>离线项目：'+this.offProjects.length+'个</p>';
            this.offProjects.forEach((value,index)=>{
                htmlStr +=`<p>${index+1}、${value.displayName}</p>`
            })
            return htmlStr;
        }
    },
    methods:{
        // 创建图表
        initEcharts1(){
            // 基于准备好的dom，初始化echarts实例
            var myChart = this.$echarts.init(document.getElementById('content1'));
            var _that = this;
            // 绘制图表
            myChart.setOption({
                tooltip: {
                    show:true,
                    trigger: 'item',
                    formatter:function(params){
                        if(params.name=='在线项目'){
                            return _that.onPjtsHTML
                        }else{
                            return _that.offPjtsHTML
                        }
                        
                    }
                },
                legend: {
                    show:false,
                    orient: 'vertical',
                    left: 'left'
                },
                color: ['rgb(20, 210, 235)', 'rgb(203, 204, 204)','#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['50%', '60%'], 
                        data: this.seriesData,
                        emphasis: {
                            itemStyle: {
                            shadowBlur: 20,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        label: {
                            normal: {
                                textStyle: {
                                    color: '#fff',	// 提示文字颜色
                                    fontSize: 15		// 提示文字大小
                                } 
                            }
                        },
                        itemStyle:{

                        }
                    }
                ],
                graphic: [{ //环形图中间添加文字
                    type: 'text', //通过不同top值可以设置上下显示
                    left: '40%',
                    top: '45%',
                    style: {
                        text: '项目总数:'+this.allProjectCount,
                        textAlign: 'center',
                        fill: '#fff', //文字的颜色
                        fontSize: 16,
                        lineHeight: 16,
                    }
                }],

        });
        },
    },
    created(){},
    mounted(){
    },
    watch:{
        pjtStateSeriesData:{
            handler(newVal,oldVal){
                if(newVal.length>0){
                    newVal.forEach(value=>{
                        this.allProjectCount += value.value
                    })
                    this.seriesData = newVal
                }else if(newVal.length==0){
                    this.seriesData = [{name:'在线项目',value:0},{name:'离线项目',value:0}]
                }
                this.$nextTick(() => {
                    this.initEcharts1();
                })
            },
            deep:true,
        },
        onlineProjects:{
            handler(newVal,oldVal){
                this.onPjts=newVal
                this.$nextTick(() => {
                    this.initEcharts1();
                })
            },
            deep:true,
            immediate: true
        },
        offProjects:{
            handler(newVal,oldVal){
                this.offPjts=newVal,
                this.$nextTick(() => {
                    this.initEcharts1();
                })
            },
            deep:true,
            immediate: true
        }
    }
 }
</script>

<style lang="less" scoped>
#content1{
    width:100%;
    height:100%;
    color: rgb(20, 210, 235);
}
</style>
<template>
    <div>
        <el-form
			ref="loginForm"
			:rules="pwdRule"
			:model="pwdData"
			label-position="left"
			label-width="100px">
			<el-form-item
				label="登录名"
				prop="loginName"
				style="width:100%">
				<el-input
					v-model="pwdData.loginName"
					clearable
					placeholder="请输入手机号或者邮箱地址"
					style="width:100%" />
			</el-form-item>
			<el-form-item
				label="密码"
				prop="inputPassword"
				style="width:100%">
				<el-input
					show-password
					v-model="pwdData.inputPassword"
					clearable
					placeholder="请输入密码"
					style="width:100%" />
			</el-form-item>
		</el-form>
		<el-row style="float: right; margin-bottom: 20px; width: 100%;">
			<el-checkbox 
				v-model="rememberPwd"
				style="float: left; margin-top: 9px">
				记住密码
			</el-checkbox>
			<el-button
				type="text"
				style="margin-left: 50px; float: right;"
				@click="onRegBtnClk">
				注册账号
			</el-button>
			<el-button
				type="text"
				style="margin-left: 50px; float: right;"
				@click="onResetPwdBtnClk">
				忘记密码
			</el-button>
		</el-row>
		<el-button
			class="loginButton"
			@click.native.prevent="loginByPwd"
			type="primary">
			登录
		</el-button>
    </div>
</template>

<script>
import { sha256 } from 'js-sha256'
import { Base64 } from 'js-base64'

import {
    loginByPwd
} from "@/api/webLogin.js"
export default {
    name: 'PwdLogin',

    data() {
        return {
            rememberPwd: false,

            // 密码登录模式的输入数据
            pwdData: { 
                loginName: '',
                inputPassword: '',
            },
            
            // 密码登录的formdata验证规则
            pwdRule: {
                loginName:  [{
                    required: true,
                    message: "请输入手机号或者邮箱地址",
                    trigger: "blur"
                }],

                inputPassword: [{
                    required: true,
                    message: "密码不能为空",
                    trigger: "blur"
                }]
            }, 
        }
    },
    mounted(){
        // 挂载组件时要判断是否回显用户名和密码
        let username = localStorage.getItem('a_specical_username')
        if (username) {
            this.pwdData.loginName = localStorage.getItem('a_specical_username')
            this.pwdData.inputPassword = Base64.decode(localStorage.getItem('a_specical_password'))// base64解密
            this.rememberPwd = true
        }
    },
    methods: {
        loginByPwd() {
            this.$refs["loginForm"].validate( async valid => {
                if (!valid) {
                    return;
                }
                
                // 判断是否记住用户名和密码
                if(this.rememberPwd){
                    localStorage.setItem('a_specical_username',this.pwdData.loginName);
                    localStorage.setItem('a_specical_password', Base64.encode(this.pwdData.inputPassword));
                } else {
                    localStorage.removeItem('a_specical_username');
                    localStorage.removeItem('a_specical_password');
                }

                let pwd256 = sha256(this.pwdData.inputPassword);
                await this.$store
                .dispatch("user/loginByPwd", {username:this.pwdData.loginName,password:pwd256})
                .then(() => {
                    try{
                        this.$store.dispatch("user/getInfo").then(res=>{
                            this.$router.push({
                                path: "/main"
                            });
                        })
                    }catch(err){
                        this.$store.dispatch("user/resetToken");
                        this.$message.error("获取用户相关信息失败！");
                    }
                    
                })
                .catch(error => {
                    console.log(error);
                });
            });
        },

        onResetPwdBtnClk() {
            this.$emit('modeChanged', 'resetPwd');
        },

        onRegBtnClk() {
            this.$emit('modeChanged', 'register');
        },
    }
}
</script>

<style lang="less" scoped>
.loginButton {
    float: middle;
    width: 80%;
    margin-left: 10%;
    height: 50px;
    border-radius: 25px;
    font-size: 15pt;
}
</style>
<template>
  <div id="monthCRBarContainer">

  </div>
</template>

<script>
  export default {
    data(){
      return{
        seriesData:[],
        yAxisData:[]
      }
    },
    props:{
        monthcharges:{
            type:Array,
        },
        monthreleases:{
            type:Array,
        }
    },
    watch:{
        monthcharges(newVal,oldVal){
            newVal.forEach(value => {
                this.yAxisData.push(value.displayName)
            })
            this.handleSeriesData()
            this.initECharts()
        }
        
    },
    methods:{
        handleYAxisCategory(){
            console.log(this.monthcharges)
            console.log(this.dayreleases)
            this.monthcharges.forEach(value => {
                this.yAxisData.push(value.displayName)
            })
        },
        handleSeriesData(){
            let obj1 = {};
            obj1.name = '本月充电量'
            obj1.type = 'bar'
            obj1.barMaxWidth = '10'
            obj1.data = []

            let obj2 = {};
            obj2.name = '本月放电量'
            obj2.type = 'bar'
            obj2.barMaxWidth = '10'
            obj2.data = []
            this.yAxisData.forEach((value)=>{
                this.monthcharges.forEach((valueC)=>{
                    if(valueC.displayName === value){
                        obj1.data.push({name:valueC.displayName,value:valueC.monthProjectCharge})
                    }
                })
                this.monthreleases.forEach((valueR)=>{
                    if(valueR.displayName === value){
                        obj2.data.push({name:valueR.displayName,value:valueR.monthProjectRelease})
                    }
                })
            })
            this.seriesData = [obj1,obj2]
        },
        initECharts(){
            let option = {
                title: {
                    text: ''
                },
                grid: {
                    top:'30%',
                    left:'2%',
                    bottom:'20%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    textStyle:{
                        color:'#fff'
                    },
                    orient: 'horizontal',
                    left: '40%' ,
                    top: '10%' ,
                },
                xAxis: {
                    type: 'value',
                    axisLabel: {
                        color:'#fff',
                        interval: 1
                    },
                },
                yAxis: {
                    type: 'category',
                    data: this.yAxisData,
                    axisLabel: {
                        color:'#fff',
                        interval: 0,
                        // formatter: function (value) {
                        //     let valueArr = value.split("");
                        //     let str = '';
                        //     for(let i=0;i<valueArr.length;i++){
                        //         str+=valueArr[i]
                        //         if(i%3==0){
                        //             str+='\n'
                        //         }
                        //     }
                        //     return str;
                        // }
                    },
                },
                series: this.seriesData
            };
            // 基于准备好的dom，初始化echarts实例
            this.$echarts.init(document.getElementById('monthCRBarContainer')).setOption(option);
        }
    },
    created(){
        
    },
    mounted(){
       
    }
 }
</script>

<style lang="less" scoped>
    #monthCRBarContainer{
        width:100%;
        height:100%;
        position:absolute;
    }
</style>